import React from "react";

import { Link } from "react-router-dom";

import "../components/MyStyling/majoy.css"; 
import {FaMicrophoneAlt} from "react-icons/fa";



 const Loader = () => {

  

  return (
    <div>
      <div className="startlog">
      <div className="logstart">
      <h1>GLOJES SUMMIT</h1>
      <p>
       A safe and healthy singing environment for choirs
      </p> 
     </div>
        <div  className="startlog-icon">
          < FaMicrophoneAlt className="famusic"/>
        </div>
        <div className="bot">
         < Link to="/Login"><button className="signlog">SIGNUP</button></Link>
        </div>
  </div>

                   

    </div>
  )
}

export default Loader