import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "../../components/MyStyling/majoy.css";
import "../../components/MyStyling/mediaqueries.css";

const MemberList = ({ data, director_id }) => {
  return (
    <div>
      <h1>MY MEMBERS</h1>
      <TableContainer className="tab"   component={Paper}>
        <Table sx={{ minWidth: 630 }} aria-label="simple table">
          <TableHead>
            <TableRow className="poptab">
              <TableCell >Last Name</TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Gender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableble">
            {data &&
              data
                .filter((d) => d.director === director_id)
                .map((filteredSection) => (
                  <tr className="tablename">
                    <TableCell className="table-cell" align="left">
                      {filteredSection.user.last_name}
                    </TableCell>
                    <TableCell className="table-cell" align="left">
                      {filteredSection.user.first_name}
                    </TableCell>
                  
                    <TableCell align="left">{filteredSection.gender}</TableCell>
                  </tr>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MemberList;
