import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";

import validation, { MemberValidation } from "../Validation";
import "../../components/MyStyling/formsStyling.css";
import "../../components/MyStyling/majoy.css";
import "../../components/MyStyling/mediaqueries.css";

import DataService from "../../api/DataService";
const REGISTER_URL = "user/";
const IMAGE_URL = "upload/";
const PROFILE_URL = "profile/";

const CreateMember = () => {
  const navigate = useNavigate();
  const directorId = useSelector((state) => state.userData.userId);
  // alert(directorId);
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [values, setvalues] = useState({});

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleRandomNum = () => {
    return randomNumberInRange(1, 20);
  };
  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setImage(event.target.files[0]);
  };

  const [errors, setError] = useState({});

  function handleChange(e) {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleUpload() {
    let postItem = new FormData();
    postItem.append("image", image);
    console.log(postItem);
    const response = await DataService.postImage(IMAGE_URL, postItem).catch(
      (err) => {
        alert(err);
      }
    );

    return response.data;
  }

  async function handleCreateUser() {
    const postItem = {
      username:
        values.first_name +
        values.last_name +
        handleRandomNum() +
        "@thisgloriousjesus.com",
      first_name: values.middle_name
        ? values.first_name + " " + values.middle_name
        : values.first_name,
      last_name: values.last_name,
      password: values.first_name + "@password",
    };
    const response = await DataService.postPublicItem(
      REGISTER_URL,
      postItem
    ).catch((err) => {
      alert(err);
    });

    return response.data;
  }

  async function handleProfile(user_id) {
    // alert(JSON.stringify(image));
    // alert(directorId);
    let postItem = {
      user: user_id,
      image: image,
      gender: values.gender,
      is_director: false,
      director: directorId,
    };

    // alert(JSON.stringify(postItem));
    const response = await DataService.postImage(PROFILE_URL, postItem).catch(
      (err) => {
        alert(err);
      }
    );
    return response.data;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(MemberValidation(values));

    if (Object.keys(MemberValidation(values)).length > 0) {
      return;
    }
    // 
    

    if (!image) {
      alert("Please select your profile image");
      return;
    }
    //add the image
    handleUpload().then((result) => {
      // setFilePath(result.file_path);
      handleCreateUser().then((res) => {
        //add the profile
        handleProfile(res.id).then((pro) => {
          navigate("/Profile");
        });
      });
    });
  };
  return (
    <div>
      <div className="log-reg">
        <div className="container">
          <div className="wrapp">
            <form onSubmit={handleSubmit}>
              <div className="title">
                <span>REGISTER YOUR MEMBERS</span>
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="First Name"
                  value={values.first_name}
                  name="first_name"
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <p style={{ color: "red", fontSize: "12px", margin: "" }}>
                    {errors.first_name}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="Middle Name"
                  value={values.middle_name}
                  name="middle_name"
                  onChange={handleChange}
                />
                {errors.church && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.middle_name}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="Last Name"
                  value={values.last_name}
                  name="last_name"
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.last_name}
                  </p>
                )}
              </div>

              <div className="gender-category">
                <input
                  type="radio"
                  name="gender"
                  id="M"
                  value="M"
                  onChange={handleChange}
                />
                <label for="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="F"
                  value="F"
                  onChange={handleChange}
                />
                <label for="female">Female</label>
                {errors.gender && (
                  <p style={{ color: "red", fontSize: "12px", margin: "" }}>
                    {errors.gender}
                  </p>
                )}
              </div>

              <div onClick={handleImageClick}>
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className="image-display-after"
                  />
                ) : (
                  <img
                    className="image-display-before"
                    src="./profileuju.jpg"
                    alt=""
                  />
                )}{" "}
                <br />
                <input
                  type="file"
                  required
                  ref={inputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <br />
              </div>

              <div className="row-row">
                <input
                  className="sublog"
                  type="submit"
                  value="REGISTER MEMBER"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </div>
              <div>
                <Link className="back" to="/Profile">
                  BACK
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMember;
