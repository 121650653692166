import React, { useState, useEffect } from "react";

import {
  FaYoutube,
  FaFacebook,
  FaWhatsapp,
  FaSearch,
  FaMusic,
} from "react-icons/fa";
import { Link } from "react-router-dom";

import "../components/MyStyling/majoy.css";
import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/mediaqueries.css";
import img18 from "./images/dor.jpg";
import img19 from "./images/singingpop.jpg";
import img20 from "./images/flagliberiab.jpg";
import img21 from "./images/doris.jpg";

import img22 from "./images/mamatennehnew.jpg";
import img23 from "./images/revrev.jpg";
import img28 from "./images/seniormickey.jpg";
import img29 from "./images/aunthibe.jpg";
import img30 from "./images/zealyn.jpg";

import img31 from "./images/nancy.jpg";
import img32 from "./images/decontee.jpg";
import img33 from "./images/ora.jpg";

const Concerts = ({ concertData, sponsorData }) => {
  const [loaded, setLoaded] = useState(false);
  const [firstData, setFirstData] = useState({});
  const [secondData, setSecondData] = useState({});
  const [thirdData, setThirdData] = useState({});
  const [fourthData, setFourthData] = useState({});
  const [fifthData, setFifthData] = useState({});

  useEffect(() => {
    const getFirstData = () => {
      let found = concertData.find((item) => item.rank === 1);
      if (found) {
        setFirstData(found);
      }
    };
    const getSecondData = () => {
      let found = concertData.find((item) => item.rank === 2);
      if (found) {
        setSecondData(found);
      }
    };
    const getThirdData = () => {
      let found = concertData.find((item) => item.rank === 3);
      if (found) {
        setThirdData(found);
      }
    };
    const getFourthData = () => {
      let found = concertData.find((item) => item.rank === 4);
      if (found) {
        setFourthData(found);
      }
    };
    const getfifthData = () => {
      setLoaded(true);
      let found = concertData.find((item) => item.rank === 5);
      if (found) {
        setFifthData(found);
      }
    };

    getFirstData();
    getSecondData();
    getThirdData();
    getFourthData();
    getfifthData();
  }, [concertData]);
  return (
    <div>
    
    <div className="con">
        <h1>CONCERTS</h1>
       </div>

      {loaded && (
        <section>
          <div className="con-row">
            <div className="conrow-one">
              <div className="conpops">
                <img className="" src={firstData.image} alt="" />
              </div>

              <div className="con-text-box">
                <h2>{firstData.title}</h2>
                <p>{firstData.description}</p>
              </div>
            </div>

            <div className="conrow-one">
              <div className="conpops">
                <img className="" src={secondData.image} alt="" />
              </div>
              <div className="con-text-box">
                <h2>{secondData.title}</h2>
                <p>{secondData.description}</p>
              </div>
            </div>
          </div>

          <section className="summitsectionrow">
            <div className="summitrowling">
              <div className="summitrowlingleft">
                <img className="" src={thirdData.image} alt="" />
                <p>
                  <marquee behavior="" direction="left">
                    <FaMusic className="FaMusic" />
                    {thirdData.description}
                    <FaMusic className="FaMusic" />
                  </marquee>
                </p>
              </div>
              <div className="summitrowlingright">
                <div className="summitcontent">
                  <h2>{fourthData.title}</h2>
                  <p>{fourthData.description}</p>
                </div>
              </div>
            </div>
          </section>

         

       
        </section>
      )}

      <footer>
        <div className="glojes-nav-but">
          <h5 className="glojes-button-name">This Glorious Jesus </h5>
          <FaYoutube className="icons-youtube" />
          <FaFacebook className="icons-facebook" />
          <FaWhatsapp className="icons-whatsapp" />
        </div>
      </footer>
    </div>
  );
};

export default Concerts;
