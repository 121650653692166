import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaYoutube, FaFacebook, FaWhatsapp } from "react-icons/fa";

const Songs = ({ songData }) => {
  //   alert(Object.keys(songData).length);
  return (
    <div>
      <div className="songs-content">
        <div className="songs-concert-content">
        
          <p>
          <h2>SONGS</h2>
            We provide these songs just for our worshipers to be aware of the
            songs this year. Kindly pay attention to these songs and practice
            them well.
          </p>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="left">Song Title</TableCell>
              <TableCell align="left">Genre/Artist</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {songData &&
              songData.map((item) => (
                <tr>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.title}</TableCell>
                  <TableCell align="left">{item.artist}</TableCell>
                </tr>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <footer>
        <div className="glojes-nav-but">
          <h5 className="glojes-button-name">This Glorious Jesus </h5>
          <FaYoutube className="icons-youtube" />
          <FaFacebook className="icons-facebook" />
          <FaWhatsapp className="icons-whatsapp" />
        </div>
      </footer>
    </div>
  );
};

export default Songs;
