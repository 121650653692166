import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { FaUser, FaLock, FaChurch } from "react-icons/fa";
import validation from "./Validation";
import "../components/MyStyling/formsStyling.css";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/mediaqueries.css";

import img1 from "./images/logocrossglo.png";
import DataService from "../api/DataService";
const REGISTER_URL = "user/";
const IMAGE_URL = "upload/";
const PROFILE_URL = "profile/";

const Register = () => {
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [values, setvalues] = useState({});

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setImage(event.target.files[0]);
  };

  const [errors, setError] = useState({});

  function handleChange(e) {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleUpload() {
    let postItem = new FormData();
    postItem.append("image", image);
    console.log(postItem);
    const response = await DataService.postImage(IMAGE_URL, postItem).catch(
      (err) => {
        alert(err);
      }
    );

    return response.data;
  }

  async function handleCreateUser() {
    const postItem = {
      username: values.email,
      first_name: values.middle_name
        ? values.first_name + " " + values.middle_name
        : values.first_name,
      last_name: values.last_name,
      password: values.password,
    };
    const response = await DataService.postPublicItem(
      REGISTER_URL,
      postItem
    ).catch((err) => {
      alert(err);
    });

    return response.data;
  }

  async function handleProfile(user_id) {
    // alert("file " + file.file_path);

    let postItem = {
      user: user_id,
      image: image,
      gender: values.gender,
      is_director: true,
      director: user_id,
    };

    // alert(postItem.image);
    const response = await DataService.postImage(PROFILE_URL, postItem).catch(
      (err) => {
        alert(err);
      }
    );
    return response.data;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(validation(values));

    if (Object.keys(validation(values)).length > 0) {
      return;
    }

    if (!image) {
      alert("Please select your profile image");
      return;
    }
    //add the image
    handleUpload().then((result) => {
      // setFilePath(result.file_path);
      handleCreateUser().then((res) => {
        //add the profile
        handleProfile(res.id).then((pro) => {
          navigate("/Login");
        });
      });
    });

    // setFilePath(response.file_path);
  };
  return (
    <div>
      <div className="log-reg">
        <div className="container">
          <div className="wrapp">
            <div className="register">
              <img src={img1} alt="" />

              <div className="reg-quotation">
                <span></span>
                <p>Thank you for joining us this year. We are glad!</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="title">
                <span>REGISTER FOR GLOJES</span>
              </div>
              <div className="row-row">
                <input
                  className=""
                  type="email"
                  placeholder="Email Address"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "12px", margin: "" }}>
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="First Name"
                  value={values.first_name}
                  name="first_name"
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <p style={{ color: "red", fontSize: "12px", margin: "" }}>
                    {errors.first_name}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="Middle Name"
                  value={values.middle_name}
                  name="middle_name"
                  onChange={handleChange}
                />
                {errors.church && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.middle_name}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="text"
                  placeholder="Last Name"
                  value={values.last_name}
                  name="last_name"
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.last_name}
                  </p>
                )}
              </div>

              <div className="gender-category">
                <input
                  type="radio"
                  name="gender"
                  id="M"
                  value="M"
                  onChange={handleChange}
                />
                <label for="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="F"
                  value="F"
                  onChange={handleChange}
                />
                <label for="female">Female</label>
                {errors.gender && (
                  <p style={{ color: "red", fontSize: "12px", margin: "" }}>
                    {errors.gender}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="password"
                  placeholder="Enter Password"
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                />
                {errors.password && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.password}
                  </p>
                )}
              </div>

              <div className="row-row">
                <input
                  className=""
                  type="password"
                  placeholder="Confirm Password"
                  value={values.confirm_password}
                  name="confirm_password"
                  onChange={handleChange}
                />
                {errors.confirm_password && (
                  <p style={{ color: "red", fontSize: "12px", margin: "1px" }}>
                    {errors.confirm_password}
                  </p>
                )}
              </div>

              <div onClick={handleImageClick}>
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className="image-display-after"
                  />
                ) : (
                  <img
                    className="image-display-before"
                    src="./profileuju.jpg"
                    alt=""
                  />
                )}{" "}
                <br />
                <input
                  type="file"
                  required
                  ref={inputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <br />
              </div>

              <div className="row-row">
                <input
                  className="sublog"
                  type="submit"
                  value="REGISTER NOW"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </div>
              <div>
                <Link className="back" to="/">
                  BACK
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
