import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FaYoutube,
  FaFacebook,
  FaWhatsapp,
  FaMapMarker,
  FaPhone,
  FaEnvelope,
  FaSearch,
} from "react-icons/fa";

import img2 from "./images/christoogh.jpg";
import img22 from "./images/christinaa.jpg";
import img14 from "./images/johnett.jpg";
import img30 from "./images/security.jpg";
import img32 from "./images/satisati.jpg";
import img33 from "./images/andrewke.jpg";
import img34 from "./images/peterjohnson.jpg";
import img35 from "./images/elkn.jpg";
import img36 from "./images/ballah.jpg";
import img37 from "./images/glojesadvert.jpg";

import { Link } from "react-router-dom";

import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/formsStyling.css";
import "../components/MyStyling/mediaqueries.css";
import DataService from "../api/DataService";

const CONTACT_URL = "contact/";

const Home = ({
  officerData,
  homeData,
  beforeStatingData,
  cautionData,
  announcementData,
  liberianSongData,
  securityData,
  advertisementData,
}) => {
  const [values, setvalues] = useState({});
  const [response, setResponse] = useState();

  function handleChange(e) {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleSendMessage() {
    const postItem = {
      email: values.email,
      message: values.message,
    };
    const response = await DataService.postPublicItem(
      CONTACT_URL,
      postItem
    ).catch((err) => {
      alert(err);
    });

    return response.data;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    handleSendMessage().then((res) => {
      setResponse("Your email has been sent. We will respond to you shortly.");
      setvalues({ ...values, email: "", message: "" });
    });
  };

  return (
    <div className="home">
      <div className="home-too">
        <div className="hometext-box">
          <div className="homemar">
          <h1>
            <marquee behavior="" direction="left">
              {homeData.title}
            </marquee>
          </h1>
          </div>
          <div className="bot">
            <Link to="/Register">
              <button className="signupup">REGISTER</button>
            </Link>
          </div>
          

        </div>
      </div>

      <div className="announ">
        <div className="ment">
          <h1>{announcementData.title}</h1>
          <p>{announcementData.description}</p>
        </div>
      </div>

      <div className="announce-row">
        <div className="announce-one">
          <div className="announce-two">
            <img className="" src={cautionData.image} alt="" />
          </div>

          <div className="announce-text-box">
            <h2>{cautionData.title}</h2>
            <p>{cautionData.description}</p>
          </div>
        </div>

        <div className="announce-one">
          <div className="announce-two">
            <img className="" src={liberianSongData.image} alt="" />
          </div>
          <div className="announce-text-box">
            <h2>{liberianSongData.title}</h2>
            <p>{liberianSongData.description}</p>
          </div>
        </div>

        <div className="announce-one">
          <div className="announce-two">
            <img className="" src={securityData.image} alt="" />
          </div>
          <div className="announce-text-box">
            <h2>{securityData.title}</h2>
            <p>{securityData.description}</p>
          </div>
        </div>
      </div>

      <section className="sectionrow">
        <div className="rowling">
          <div className="rowlingleft">
            <img className="" src={beforeStatingData.image} alt="" />
          </div>
          <div className="rowlingright">
            <div className="content">
              <h2>{beforeStatingData.title}</h2>
              <p>{beforeStatingData.description}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="directors-pop">
        <div className="ceos">
          <h4>Meet the CEOs</h4>
        </div>

        <div className="directors-row">
          {officerData.map((item) => {
            return (
              <div className="directors-one">
                <div className="directors-two">
                  <img className="" src={item.image} alt="officer image" />
                </div>

                <div className="dirbox">
                  <Link className="dirlink" to={item.link}>
                    {item.fullname}
                  </Link>
                  <p>{item.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>


    

      
          


      <section className="advert-row">
        <div className="advert-one">
          <div>
            <h2 className="adverthead">

            <marquee behavior="" direction="left">
             {advertisementData.title}
            </marquee>

            </h2>
            <img className="adverth" src={advertisementData.image} alt="" />
          </div>

        
        </div>
      </section>

      <section className="contact">
        <div className="content">
          <h2>Contact Us:</h2>
        </div>
        <div className="container-ner">
          <div className="contact-info">
            <div className="box-box">
              <div className="icons">
                <FaMapMarker className="FaMapMarker" />
              </div>
              <div className="text">
                <h3>Address</h3>
                <p>
                  Johnsonville, Monrovia Liberia.
                </p>
              </div>
            </div>

            <div className="box-box">
              <div className="icons">
                <FaPhone className="FaPhone" />
              </div>
              <div className="text">
                <h3>Tele-Phone</h3>
                <p>+231776320624</p>
              </div>
            </div>

            <div className="box-box">
              <div className="icons">
                <FaEnvelope className="FaEnvelope" />
              </div>
              <div className="text">
                <h3>Email</h3>
                <p>thisgloriousjesus2022@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <h2>Send Message</h2>
              <br />
              <br />
              <h3>{response}</h3>
              <br />
              <br />
              <div className="inputbox">
                <input
                  type="text"
                  name="email"
                  required
                  value={values.email}
                  onChange={handleChange}
                />
                <span>Email</span>
              </div>
              <div className="inputbox">
                <textarea
                  required
                  rows={5}
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                ></textarea>
                <span>Type Your Message...</span>
              </div>
              <div className="inputbox">
                <input
                  type="submit"
                  name=""
                  required=""
                  className="submit-mit"
                  value="Send"
                />
              </div>
            </form>
          </div>
        </div>
      </section>

      <footer>
        <div className="glojes-nav-but">
          <h5 className="glojes-button-name">This Glorious Jesus </h5>
          <FaYoutube className="icons-youtube" />
          <FaFacebook className="icons-facebook" />
          <FaWhatsapp className="icons-whatsapp" />
        </div>
      </footer>
    </div>
  );
};

export default Home;
