import React from 'react'

import "../components/MyStyling/majoy.css";
import "../components/StylesImage/imageStylling.css";

import {FaYoutube, FaFacebook, FaWhatsapp, FaSearch} from "react-icons/fa";

import img42 from "./images/fedesco.jpg";
import img43 from "./images/youth.jpg"; 
import img44 from "./images/black.jpg"; 



const About = () => {
  return (
    <div>

           

            <div className="glo-too">

            

                  <div className="text-box">
                   <h1>THIS GLORIOUS JESUS</h1>
                  </div>
            </div>

         
      
                         
                                  
                                  <div className="about">
                                          <div className="aboutabout">
                                          <h1>ABOUT US:</h1>
                                          <p> 
                                                This Glorious Jesus (GLOJES) Regional Choir Singing summit is a national base rganization that  involve with
                                                training and maintaining of choirs locally and nationally.
                                                We believe that worshippers who sing worship songs to Jesus must have a clear understanding of  the Holy Bible.
                                                Our goal is to host national worship concerts where thousands of people will gather to worship Jesus Christ.
                                                Not only limited to to concerts, we are engage in choirs training, worship, retreat and camps.
                                                Every year the GLOJES summit is organize to bring worshippers together with one mind.
                                                We want to join many Christians in the world to show how glorious Jesus is and that many souls will be won.
                                              
                                                This Glorious Jesus (GLOJES) Worship Experience is an  annual worship concert
                                                base in Monrovia, Liberia. It all begins from the Township of Johnsonville. 
                                                This Experience is based on a christian disciplain and principles. Yearly, worshippers from all
                                                local churches pay a visit to the summit after registration with their musical
                                                directors and the concern of their parents and pastors. 
                                                The first summit was held at the Jubilee Praise and Worship Center, Johnsonville branch 
                                                with the climax at the Christ Salvation Ministry 
                                                due to the numbers of individual that Jubilee could not host.
                                          </p>
                                              
                                          </div>
                                  
                                  </div>                      
                                 
    
    <articles className="tours-all">
                <div className="our-tour">
                  <h1>OUR TOURS:</h1>
                </div>
          <div className="tour-back">
                <div className="tour-row">
                      <div className="tour-0ne">
                          <div className="one-one">
                              <img className="" src={img42} alt="" />
                          </div>
                              <div className="one-one-text-box">
                              <h1>Christ Liberation League</h1>
                                <p>
                                This Glorious Jesus (GLOJES) Worship Experience is an  annual worship concert
                                base in Monrovia, Liberia. It all begins from the Township of Johnsonville. 
                                This Experience is based on a christian disciplain and principles. Yearly, worshippers from all
                                local churches pay a visit to the summit after registration with their musical
                                directors and the concern of their parents and pastors. 
                                </p>
                              </div>
                      </div>
          
         
                 <div className="tour-two">
                     <div className="one-one">
                        <img className="" src={img43} alt="" />
                     </div>
                        <div className="one-one-text-box">
                        <h1>Invasion Tower Ministry</h1>
                          <p>
                          This Glorious Jesus (GLOJES) Worship Experience is an  annual worship concert
                          base in Monrovia, Liberia. It all begins from the Township of Johnsonville. 
                          This Experience is based on a christian disciplain and principles. Yearly, worshippers from all
                          local churches pay a visit to the summit after registration with their musical
                          directors and the concern of their parents and pastors. 
                          
                          </p>
                        </div>
                 </div>

                 <div className="tour-three">
                     <div className="one-one">
                        <img className="" src={img44} alt="" />
                     </div>
                        <div className="one-one-text-box">
                          <h1>Christ Salvation Ministry</h1>
                          <p>
                          This Glorious Jesus (GLOJES) Worship Experience is an  annual worship concert
                          base in Monrovia, Liberia. It all begins from the Township of Johnsonville. 
                          This Experience is based on a christian disciplain and principles. Yearly, worshippers from all
                          local churches pay a visit to the summit after registration with their musical
                          directors and the concern of their parents and pastors. 
                          </p>
                        </div>
                 </div>
                    
                </div>

          </div>       
    </articles>

      
      
             
           <div className="glojes-nav-but">
              <h5 className="glojes-button-name">This Glorious Jesus </h5>
              <FaYoutube  className="icons-youtube"/>
              <FaFacebook className="icons-facebook"/>
              <FaWhatsapp className="icons-whatsapp"/>
          </div>
      
    
       



 






              


      
        


    </div>
  )
}

export default About
