import { ActionEnum } from "../utility/MyEnum.ts";

export const userId = (userId) => {
  return {
    type: ActionEnum.UserId,
    payload: userId,
  };
};

export const authenticated = (authenticated) => {
  return {
    type: ActionEnum.Authenticated,
    payload: authenticated,
  };
};

export const profileId = (profileId) => {
  return {
    type: ActionEnum.ProfileId,
    payload: profileId,
  };
};

export const accessToken = (accessToken) => {
  return {
    type: ActionEnum.AccessToken,
    payload: accessToken,
  };
};
