import axios from "axios";
// let token = localStorage.getItem("token");

// const BASE_URL = "http://127.0.0.1:8000/api/";
const BASE_URL = "https://thisglojes.pythonanywhere.com/api/";

class DataService {
  getPublicItemList = async (url) => {
    return await axios.get(BASE_URL + url);
  };

  getItemList = async (url, token) => {
    return await axios.get(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };

  getItemListWithParams = async (url, token, config) => {
    return await axios.get(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
      params: config && config,
    });
  };

  getSelectItemList = async (url, token) => {
    return await axios.get(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };

  postPublicItem = async (url, data) => {
    // alert(BASE_URL);
    // alert(url);
    // alert(data.username);
    return await axios.post(BASE_URL + url, data);
  };

  postImage = async (url, data) => {
    return await axios.post(BASE_URL + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  postItem = async (url, data, token) => {
    return await axios.post(BASE_URL + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };

  getItemById = async (url, dataId, token) => {
    // alert(token);
    return await axios.get(BASE_URL + url + dataId, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };

  updateItem = async (url, dataId, data, token) => {
    return await axios.patch(BASE_URL + url + dataId + "/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };

  deleteItem = async (url, dataId, token) => {
    return await axios.delete(BASE_URL + url + dataId + "/", {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: false,
      },
    });
  };
}

export default new DataService();
