import React from 'react'


import img18 from "./images/kasselie.jpg";

import {FaYoutube, FaFacebook, FaWhatsapp, FaMapMarker, FaPhone, FaEnvelope, FaSearch,  } from "react-icons/fa";
import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/majoy.css"; 
import "../components/MyStyling/mediaqueries.css"

const Brandinfluencer = () => {
  return (
    <div>

<div className="directors-one">
                         <div className="directors">
                         <img className="" src={img18} alt="" />
                         </div>

                       <div className="andrewprofile">
                       
                                         <p>
                       
                                            Andrew Kasselie is a child of God. A comtemposrary gospel
                                            singer and song writer. He was born in Gbanga, Bong County Liberia.
                                            His family later moved to Monrovia where he was raised. 
                                            "I recognized the called of God on my life as a worship leader at age 12."
                                            He is currently the a member of the (GLOJES) Worship Experience. 
                                            Andrew Kasselie won best singer of the year award title (SHM AWARD) Soul Harvester
                                            Ministry Award in 2020. 
                                          </p>
                        </div>
                    </div>

          <footer>
             <div className="glojes-nav-but">
                 <h5 className="glojes-button-name">This Glorious Jesus </h5>
                 <FaYoutube  className="icons-youtube"/>
                 <FaFacebook className="icons-facebook"/>
                 <FaWhatsapp className="icons-whatsapp"/>
             </div>
          </footer>

    </div>
  )
}

export default Brandinfluencer