import React, { useEffect, useState } from "react";
import "./App.css";

import Home from "./components/Home";
import Rules from "./components/Rules";

import Concerts from "./components/Concerts";
import About from "./components/About";

import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import Awards from "./components/Awards";
import Register from "./components/Register";
import Account from "./components/Account";
import Programdirector from "./components/Programdirector";
import Brandinfluencer from "./components/Brandinfluencer";
import Musicpastor from "./components/Musicpastor";
import Musicpastorpop from "./components/Musicpastorpop";
import Musicpastorpoppop from "./components/Musicpastorpoppop";
import Addmembers from "./components/Addmembers";
import Songs from "./components/Songs";
import Loader from "./components/Loader";
import View from "./components/View";
import CreateMember from "./components/member/CreateMember";

import Login from "./components/Login";
import Profile from "./components/Profile";
import axios from "axios";
import { ContentEnum } from "./utility/MyEnum.ts";
import DataService from "./api/DataService";
import { useSelector } from "react-redux";

const BASE_URL = "http://127.0.0.1:8000/api/";
const CONTENT_URL = "content/";
const OFFICER_URL = "officer/";
const RULES_URL = "rule/";
const CONCERT_URL = "concert/";
const SPONSOR_URL = "sponsor/";
const SONG_URL = "song/";

function App() {
  const authenticated = useSelector((state) => state.userData.authenticated);
  const [hasData, setHasData] = useState(false);
  const [officerData, setOfficerData] = useState([]);
  const [ruleData, setRuleData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [beforeStatingData, setBeforeStartingData] = useState([]);
  const [cautionData, setCautionData] = useState([]);
  const [announcementData, setAnnouncementData] = useState([]);
  const [liberianSongData, setLiberianSongData] = useState([]);
  const [securityData, setSecurityData] = useState([]);
  const [advertisementData, setAvertisementData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [ethicsData, setEthicsData] = useState([]);
  const [guideLineData, setGuideLineData] = useState([]);
  const [concertData, setConcertData] = useState([]);
  const [sponsorData, setSponsorData] = useState([]);
  const [songData, setSongData] = useState([]);

  useEffect(() => {
    const getSiteData = async () => {
      DataService.getPublicItemList(CONTENT_URL)
        .then((res) => {
          // console.log(res.data);
          setSiteData(res.data);
          setHasData(true);
        })
        .catch((err) => console.log(err));
    };

    const getOfficeData = async () => {
      DataService.getPublicItemList(OFFICER_URL)
        .then((res) => {
          // console.log(res.data);
          setOfficerData(res.data);
        })
        .catch((err) => console.log(err));
    };

    const getSponsorData = async () => {
      DataService.getPublicItemList(SPONSOR_URL)
        .then((res) => {
          // console.log(res.data);
          setSponsorData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getOfficeData();

    getSponsorData();

    getSiteData();
  }, []);

  useEffect(() => {
    const getHomeData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.HOME_CONTENT
      );
      if (selectedItem) {
        setHomeData(selectedItem);
      }
    };

    const getBeforeStatingData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.BEFORE_YOU_START
      );
      if (selectedItem) {
        setBeforeStartingData(selectedItem);
      }
    };

    const getCautionData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.CAUTION
      );
      if (selectedItem) {
        setCautionData(selectedItem);
      }
    };

    const getAnnouncementData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.ANNOUNCEMENT
      );
      if (selectedItem) {
        setAnnouncementData(selectedItem);
      }
    };

    const getLiberianSongData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.LIBERIAN_SONGS
      );
      if (selectedItem) {
        setLiberianSongData(selectedItem);
      }
    };

    const getSecurityData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.SECURITY
      );
      if (selectedItem) {
        setSecurityData(selectedItem);
      }
    };

    const getAdvertisementData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.ADVERTISEMENT
      );
      if (selectedItem) {
        setAvertisementData(selectedItem);
      }
    };

    const getGoalData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.GOAL
      );
      if (selectedItem) {
        setGoalData(selectedItem);
      }
    };

    const getEthicsData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.ETHICS
      );
      if (selectedItem) {
        setEthicsData(selectedItem);
      }
    };

    const getGuideLineData = () => {
      let selectedItem = siteData.find(
        (item) => item.content_type === ContentEnum.GUIDE_LINE
      );
      if (selectedItem) {
        setGuideLineData(selectedItem);
      }
    };
    const getRuleData = async () => {
      DataService.getPublicItemList(RULES_URL)
        .then((res) => {
          // console.log(res.data);
          setRuleData(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getConcertData = async () => {
      DataService.getPublicItemList(CONCERT_URL)
        .then((res) => {
          // console.log(res.data);
          setConcertData(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getSongData = async () => {
      DataService.getPublicItemList(SONG_URL)
        .then((res) => {
          // console.log(res.data);
          setSongData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getHomeData();
    getBeforeStatingData();
    getCautionData();
    getAnnouncementData();
    getLiberianSongData();
    getSecurityData();
    getAdvertisementData();
    getGoalData();
    getEthicsData();
    getGuideLineData();
    getRuleData();
    getConcertData();
    getSongData();
  }, [hasData]);

  return (
    <div className="App">
      <React.Fragment>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              hasData && (
                <Home
                  officerData={officerData}
                  homeData={homeData}
                  beforeStatingData={beforeStatingData}
                  cautionData={cautionData}
                  announcementData={announcementData}
                  liberianSongData={liberianSongData}
                  securityData={securityData}
                  advertisementData={advertisementData}
                />
              )
            }
          />
          <Route path="/About" element={<About />} />
          
          <Route
            path="/Rules"
            element={
              <Rules
                goalData={goalData}
                ethicsData={ethicsData}
                guideLineData={guideLineData}
                ruleData={ruleData}
              />
            }
          />
          <Route
            path="/Concerts"
            element={
              <Concerts concertData={concertData} sponsorData={sponsorData} />
            }
          />
          <Route path="/Awards" element={<Awards />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Programdirector" element={<Programdirector />} />
          <Route path="/Brandinfluencer" element={<Brandinfluencer />} />
          <Route path="/Musicpastor" element={<Musicpastor />} />
          <Route path="/Musicpastorpop" element={<Musicpastorpop />} />
          <Route path="/Musicpastorpoppop" element={<Musicpastorpoppop />} />
          <Route path="/Addmembers" element={<Addmembers />} />
          <Route path="/View" element={<View />} />
          <Route path="/Songs" element={<Songs songData={songData} />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Loader" element={<Loader />} />
          <Route path="/Profile" element={authenticated && <Profile />} />
          <Route
            path="/CreateMember"
            element={authenticated && <CreateMember />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>
    </div>
  );
}

export default App;
