import { configureStore } from "@reduxjs/toolkit";
import AllReducers from "../reducer/Index";

const store = configureStore({
  reducer: AllReducers,
});

export default store;

// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "@redux-devtools/extension";
// import allReducers from "../reducer/Index";
// import thunk from "redux-thunk";

// // const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// const store = createStore(
//   allReducers,
//   composeWithDevTools(
//     applyMiddleware(thunk)
//     // other store enhancers if any
//   )
// );

// export default store;
