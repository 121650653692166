import React from 'react'
import img10 from "./images/chrissatisatic.jpg";

import {FaYoutube, FaFacebook, FaWhatsapp } from "react-icons/fa";


import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/formsStyling.css"
import "../components/MyStyling/mediaqueries.css"

const Programdirector = () => {
  return (
    <div>

     
                    <div className="directors-one">
                         <div className="directors">
                         <img className="" src={img10} alt="" />
                         </div>

                       <div className="chrisprofile">
                       
                       <p>
                                                  Chris B. Sati born December 8,1995 in Fehnpolu (Grand Bassa County).  His Parents
                                                  are Bendu M. Sati and Jimmy K. Sati. He is married to a beautiful lady called Jeridel Scott Sati. 
                                                  "I started loving music  when I began to hear the Lutheran Church
                                                  Children Choir sing in Duportroad."  He was the founder of the formal Project
                                                  MyTone Gospel Singing Competition that he later shut down due to situations and failures. But he said in an interview
                                                  at Truth FM Radio Station " I didn't fail with Project MyTone; it was just not 
                                                  what I was  called to do. So when I discover that worship is not about competition,
                                                  it turned things around."

                                                    Mr. Sati has worked with many churches choirs in Monrovia with his worship and
                                                    directing skills. Some include the Jubilee Praise and Worship Center Johnsonville, 
                                                    More than a conquerors Faith Assembly, Christ Power, Invasion Tower Ministry, 
                                                    Mercy Chapel Int'l Liberia etc. Mr. Sati is currently with the Santuary of
                                                    Praise Ministry Choir in Johnsonville Backstreet Community. After many years of learning his team 
                                                    decided to form a worship experience that will be known to the world. With the help 
                                                    of God(Jesus) and other directors they started a new team with the label 
                                                    "This Glorious Jesus (GLOJES).
                                                </p>
                                              
                        </div>
                    </div>


          <footer>
             <div className="glojes-nav-but">
                 <h5 className="glojes-button-name">This Glorious Jesus </h5>
                 <FaYoutube  className="icons-youtube"/>
                 <FaFacebook className="icons-facebook"/>
                 <FaWhatsapp className="icons-whatsapp"/>
             </div>
          </footer>
                     

    </div>
  )
}

export default Programdirector