import React, { useState, useEffect } from "react";

import { FaLock, FaUser } from "react-icons/fa";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/formsStyling.css";

import { Link, useNavigate } from "react-router-dom";
import DataService from "../api/DataService";
import axios from "axios";

import { useDispatch } from "react-redux";
import { userId, authenticated, profileId, accessToken } from "../action/Index";

import { jwtDecode } from "jwt-decode";
const LOGIN_URL = "login/";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setvalues] = useState({});
  const [token, setToken] = useState();

  function handleChange(e) {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleLogin(event) {
    event.preventDefault();
    const postItem = {
      username: values.email,
      password: values.password,
    };
    // alert(postItem.username);
    // alert(postItem.password);
    const response = await DataService.postPublicItem(LOGIN_URL, {
      username: postItem.username,
      password: postItem.password,
    }).catch((err) => {
      alert("Invalid username or password");
    });

    if (response) {
      // alert(JSON.stringify(response));
      setToken(response.data.access);
      dispatch(accessToken(response.data.access));
      let token = jwtDecode(response.data.access);
      console.log(token);
      dispatch(userId(token.user_id));
      dispatch(profileId(token.profile_id));
      dispatch(authenticated(true));
      navigate("/Profile");
    }
  }

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
  }, [token]);

  return (
    <div>
      <div className="box-in">
        <div className="box">
          <form>
            <h1>SIGNIN FOR GLOJES</h1>
            <div className="inputbox">
              <input
                type="email"
                required="required"
                value={values.email}
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <FaUser className="user" />
              <span>Email</span>
              <i></i>
            </div>

            <div className="inputbox">
              <input
                type="password"
                required="required"
                value={values.password}
                name="password"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <FaLock className="user" />
              <span>Password</span>
              <i></i>
            </div>

            <div className="links">
              <a href="#">Forgot Password</a>
              <a href="#">
                <Link to="/Register">Register ?</Link>
              </a>
            </div>
            <input
              onClick={(e) => {
                handleLogin(e);
              }}
              type="submit"
              value="Login"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
