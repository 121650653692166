import React from 'react'
import img16 from "./images/peterhhh.jpg";

import {FaYoutube, FaFacebook, FaWhatsapp, FaMapMarker, FaPhone, FaEnvelope, FaSearch,  } from "react-icons/fa";


import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/formsStyling.css"
import "../components/MyStyling/mediaqueries.css"


const Musicpastor = () => {
  return (
    <div>

               <div className="directors-one">
                         <div className="directors">
                         <img className="" src={img16} alt="" />
                         </div>

                       <div className="chrisprofile">
                       
                                           <p>
                                              Peter Master Johnson Sr is a pastor. He is married to Mrs. Kona Blessing Benson Johnson. 
                                              He was born into the union of Mr. and Mrs. Johnson, in the year 1998, the 19th of September, 
                                              in Suakoko  District Bong County the Republic of Liberia. He graduated from the Oluremi Tinubu 
                                              Public School located in the township of Johnsonville Monrovia- Liberia, in the year 2018 - 2019.
                                              Mr. Johnson is presently a student of the LICOSESS Teachers' College, and also a student of the T Net Bible 
                                              College, both in Monrovia-Liberia. "I started worshipping the Lord through songs and deeds at the 
                                              age of four (4) , while staying on a mission used to be called, Koko Village."" Mr Johnson grew up in a Christian
                                              home, and was taught by my parents (Mr and Mrs Johnson) who are now pastors, the ways of God. He is
                                              presently serving as a Music Pastor of Mercy Chapel Int. Liberia Ministry, which founder is, Rev.
                                              Wisdom Zio. Mr. Johnson is also the founder of a music group called "Call To Worship". "I bless the Lord who has 
                                              empowered me with his gift and grace, that have enabled me to raise a lots of gospel singers, 
                                              choirs, instrumentalists and youths."  He is also one of the Music Directors of the Voice Of Heaven Mass
                                              Choir and a Music Teacher/Director of This Glorious Jesus (GLOJES), both base in township of
                                              Johnsonville Monrovia-Liberia.
                                           </p>
                        </div>
                    </div>

          <footer>
             <div className="glojes-nav-but">
                 <h5 className="glojes-button-name">This Glorious Jesus </h5>
                 <FaYoutube  className="icons-youtube"/>
                 <FaFacebook className="icons-facebook"/>
                 <FaWhatsapp className="icons-whatsapp"/>
             </div>
          </footer>

    </div>
  )
}

export default Musicpastor