import { ActionEnum } from "../utility/MyEnum.ts";
const initialState = {
  userId: 0,
  profileId: 0,
  accessToken: "",
  authenticated: false,
};
const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionEnum.UserId:
      state = { ...state, userId: action.payload };
      return state;

    case ActionEnum.ProfileId:
      state = { ...state, profileId: action.payload };
      return state;

    case ActionEnum.Authenticated:
      state = { ...state, authenticated: action.payload };
      return state;

    case ActionEnum.AccessToken:
      state = { ...state, accessToken: action.payload };
      return state;

    default:
      return state;
  }
};

export default AuthenticationReducer;
