import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";



import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DataService from "../api/DataService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticated } from "../action/Index";

import img42 from "./images/glojessafe.jpg";

import "../components/MyStyling/majoy.css";
import "../components/MyStyling/mediaqueries.css";
import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/profile.css";

import { Avatar, Button } from "@mui/material";
import MemberList from "./member/MemberList";

const CONTACT_URL = "contact/";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const USER_URL = "user/";
const PROFILE_URL = "user_profile/";
const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user_id = useSelector((state) => state.userData.userId);
  const proflie_id = useSelector((state) => state.userData.profileId);
  const accessToken = useSelector((state) => state.userData.accessToken);

  const [userData, setUserData] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [directorMembers, setDirectorMembers] = useState({});
  const [loaded, setLoaded] = useState(false);
  //   alert(user_id);

  async function getUserDetail() {
    try {
      const response = await DataService.getItemById(
        USER_URL,
        user_id,
        accessToken
      )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          alert(err);
        });

      // alert(JSON.stringify(response.data));
      return response.data;
    } catch (e) {
      console.log("Error");
    }
  }

  async function getUserProfileDetail() {
    try {
      const response = await DataService.getItemById(
        PROFILE_URL,
        proflie_id,
        accessToken
      )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          alert(err);
        });

      return response;
    } catch (e) {
      console.log("Error");
    }
  }
  async function getUserProfileData() {
    // alert(proflie_id);
    getUserDetail().then((user) => {
      setUserData(user);
      // alert(proflie_id);
      getUserProfileDetail().then((profile) => {
        setUserProfile(profile.data);
      });
    });
  }

  const handleLogout = () => {
    dispatch(authenticated(false));
    navigate("/Login");
  };

  async function getDirectorMembers() {
    try {
      const response = await DataService.getPublicItemList(PROFILE_URL)
        .then((res) => {
          setLoaded(true);
          return res;
        })
        .catch((err) => {
          alert(err);
        });
      setLoaded(true);
      let myMembers = response.data.filter((fil) => fil.director === user_id);
      myMembers = myMembers.filter((fil) => fil.is_director === false);
      return myMembers;
    } catch (e) {
      console.log("Error");
    }
  }

  useEffect(() => {
    if (user_id == 0) {
      navigate("/Login");
      return;
    }
    getUserProfileData();

    getDirectorMembers().then((res) => {
      setDirectorMembers(res);
    });
  }, []);

  const [values, setvalues] = useState({});
  const [response, setResponse] = useState();

  function handleChange(e) {
    setvalues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleSendMessage() {
    const postItem = {
      email: values.email,
      message: values.message,
    };
    const response = await DataService.postPublicItem(
      CONTACT_URL,
      postItem
    ).catch((err) => {
      alert(err);
    });

    return response.data;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    handleSendMessage().then((res) => {
      setResponse("Your email has been sent. We will respond to you shortly.");
      setvalues({ ...values, email: "", message: "" });
    });
  };


  return (

   <div className="acct">

                          <div className="safe">
                              <img className="" src={img42} alt="" />
                          </div>

    <Box className="probox" >
      <Grid className="progrid" container spacing={1}>
        <Grid item xs={12} md={2}>
          <Item xs={{ padding: 1 }}>
            {loaded && (
              <div className="propro">
                <h2>PROFILE</h2>
                <Avatar className="avaimage"
                  alt={userData.first_name}
                  src={userProfile.image}
                  sx={{ width: 100, height: 100 }}
                />
                <br />
                <div>
                 <b className="proname">
                  {userData.first_name} {userData.last_name}
                 </b>
                 
                 <div className="gendergen">
                 {userProfile.gender}
                 </div>
                
                 <div className="gendergen">

                  <div className="email">
                     {userData.username}
                  </div>
                 <br/>
                 {userProfile.is_director ? (
                  <span className="gendergen">Director</span>
                 ) : (
                   <sp>Member</sp>
                  )}

                  </div>
                </div>
              </div>
            )}
            <br />
            <br />

            {userProfile.approved ? (
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/CreateMember");
                }}
              >
                Add Member
              </Button>
            ) : (
              <Box>
                <Button variant="contained" disabled>
                  Add Member
                </Button>
                <br />
                <p>
                  You have registered but will not be able to add members until
                  you have made payment and the payment gets approved.
                </p>
                <br />
                <p>
                  Mobile Money: 0555626686 with account name This Glorious Jesus
                </p>
              </Box>
            )}

            <br />
            <br />
            <Button
              variant="outlined"
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </Button>
            <br />
          </Item>
        </Grid>
        <Grid className="gridgrid" item xs={12} md={10}>
          
          <Item >
            {loaded && (
              <MemberList className="direclist" data={directorMembers} director_id={user_id} />
            )}
          </Item>
          
        </Grid>
      </Grid>
    </Box>

    
    <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <h2>Send Message</h2>
              <br />
              <br />
              <h3>{response}</h3>
              <br />
              <br />
              <div className="inputbox">
                <input
                  type="text"
                  name="email"
                  required
                  value={values.email}
                  onChange={handleChange}
                />
                <span>Email</span>
              </div>
              <div className="inputbox">
                <textarea
                  required
                  rows={5}
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                ></textarea>
                <span>Type Your Message...</span>
              </div>
              <div className="inputbox">
                <input
                  type="submit"
                  name=""
                  required=""
                  className="submit-mit"
                  value="Send"
                />
              </div>
            </form>
          </div>
    

    </div>
  );
};

export default Profile;
