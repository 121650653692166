
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import "../components/MyStyling/majoy.css";
import { Link } from "react-router-dom";


 

const Account = () => {

const [categories, setCategories] = useState([]);

useEffect (()  => {

  axios.get("https://api.publicapis.org/categories")
  .then((res) =>{
    setCategories(res.data);
    
  })

}, [])


  return (
    <div>
        

          
        <div className="wel">  
             <Link to="/Addmembers"><button>ADD MEMBERS</button></Link>
        </div>
        

        <h1 className="your-account">WECOME TO YOUR ACCOUNT!</h1>

        <div className="col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                   <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                       <div class="overflow-hidden">
                            <table class="min-w-full text-center text-sm font-light">
                              <thead
                                class="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                                <tr>
                                  <th scope="col" className="list">#</th>
                                  <th scope="col" className="list">Name</th>
                                  <th scope="col" className="list">Tone</th>
                                  <th scope="col" className="list">Personal Contact</th>
                                  <th scope="col" className="list">Parent's Contact</th>
                                  <th scope="col" className="list">Action</th>
                                </tr>
                              </thead>
                              
                                <tbody>
           
                                  

                                      <tr  className="members-roll">

                                         <td className="one">1</td>
                                         <td className="first-name">Fedesco Lomax </td>            
                                            
                                            {console.log(categories)}
                                            <td className="tone">Tenor</td>
                                            <td className="number">90797</td>
                                            <td className="p-number">008999</td>

                                                <td className="edit-btn">
                                                      <button className="view">View</button>
                                                      <button className="edit">Edit</button> 
                                                      <button className="delect" >Delect</button>
                                                </td>
               
                                       </tr>
             
                                 </tbody>
                            </table>
                        </div>
                    </div>
              </div>
        </div>
      
    </div>
  )
}

export default Account
