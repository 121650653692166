import React from 'react'
import "../components/MyStyling/mediaqueries.css"

import { FaYoutube, FaFacebook, FaWhatsapp} from "react-icons/fa";

import "../components/MyStyling/majoy.css";
import "../components/StylesImage/imageStylling.css";
import img22 from "./images/singingpop.jpg";

const Awards = () => {
  return (
    <div>


          <div className="guidelines">
            
            <div className="guidelines">
              <img className="" src={img22} alt="" />
            </div>

              <div className="guidelines-text-box">
                    <p>
                    <h1>GUIDELINES</h1>
                     This program is a spiritual encounter and require a
                     focus mind from the worshipers. Any art of disobedience will lead to having the pastor of your
                     church inform and sending you home during the session.  Please behave accordingly.
                    </p>
              </div>
          </div>
   
         <div className="glojes-nav-but">
              <h5 className="glojes-button-name">This Glorious Jesus </h5>
              <FaYoutube  className="icons-youtube"/>
              <FaFacebook className="icons-facebook"/>
              <FaWhatsapp className="icons-whatsapp"/>
          </div>

    </div>
  )
}

export default Awards
