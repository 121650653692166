import React from "react";

import img20 from "./images/hands.jpg";
import img21 from "./images/worwor.jpg";
import img22 from "./images/praisenkona.jpg";

import {
  FaYoutube,
  FaFacebook,
  FaWhatsapp,
  FaHandPointRight,
  FaSearch,
} from "react-icons/fa";

import { Link } from "react-router-dom";

import "../components/MyStyling/majoy.css";
import "../components/StylesImage/imageStylling.css";

const Rules = ({ 
   goalData,
   ethicsData, 
   guideLineData, 
   ruleData }) => {
  return (
    <div>
       
       <div className="rls">
        <h1>RULES</h1>
       </div>

      <div className="rules-row">
        <div className="rulesrow-one">
          <div className="rulespops">
            <img className="" src={goalData.image} alt="" />
          </div>

          <div className="rulesrow-text-box">
            <h2>{goalData.title}</h2>
            <p>{goalData.description}</p>
          </div>
        </div>

        <div className="rulesrow-one">
          <div className="rulespops">
            <img className="" src={ethicsData.image} alt="" />
          </div>
          <div className="rulesrow-text-box">
            <h2>{ethicsData.title}</h2>
            <p>{ethicsData.description}</p>
          </div>
        </div>
      </div>

      <div className="guidelines">
        <div className="guidelines">
          <img className="" src={guideLineData.image} alt="" />
        </div>

        <div className="guidelines-text-box">
          <p>
            <h2>{guideLineData.title}</h2>
            {guideLineData.description}
          </p>
        </div>
      </div>

      <section className="major-rules-section">
        <div className="major-rules">
          {ruleData.map((item) => {
            return (
              <div className="one-rule">
                <div className="rule-textbox">
                  <div className="rules-icon">
                    <FaHandPointRight className="FaHandPointRight" />
                  </div>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <br />
      <hr />

      <div className="glojes-nav-but">
        <h5 className="glojes-button-name">This Glorious Jesus </h5>
        <FaYoutube className="icons-youtube" />
        <FaFacebook className="icons-facebook" />
        <FaWhatsapp className="icons-facebook" />
      </div>
    </div>
  );
};

export default Rules;
