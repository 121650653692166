export const MemberValidation = (values) => {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = "Please enter your first name";
  }

  if (!values.last_name) {
    errors.last_name = "Please enter the last name";
  }

  if (!values.gender) {
    errors.gender = "Please select the gender";
  }

  return errors;
};

const Validation = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter your email address e.g. someone@domain.com";
  } else {
    if (!validateEmail(values.email)) {
      errors.email = "Please enter valid email address e.g. someone@domain.com";
    }
  }
  if (!values.first_name) {
    errors.first_name = "Please enter your first name";
  }

  if (!values.last_name) {
    errors.last_name = "Please enter the last name";
  }

  if (!values.gender) {
    errors.gender = "Please select the gender";
  }

  if (!values.password) {
    errors.password = "Please enter your password!";
  } else if (values.password.length < 4) {
    errors.password = "Password must be at least 4 character";
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Please confirm your password";
  }

  if (!(values.password === values.confirm_password)) {
    errors.confirm_password = "The password must match its confirmation";
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  return errors;
};

export default Validation;
