import React from 'react'

import {FaYoutube, FaFacebook, FaWhatsapp } from "react-icons/fa";

import img37 from "./images/ballah.jpg";

import "../components/StylesImage/imageStylling.css";
import "../components/MyStyling/majoy.css";
import "../components/MyStyling/formsStyling.css"
import "../components/MyStyling/mediaqueries.css"

const Musicpastorpoppop = () => {
  return (
    <div>
       <div className="directors-one">
                         <div className="directors">
                         <img className="" src={img37} alt="" /> 
                         </div>

                       <div className="chrisprofile">
                       
                                         <p>
                                              Abrahm B Zayzay is a Liberian. He was born on the 6th day of June , 1998. 
                                              He grew up in a christian home, under the watch of his lovely parents, 
                                              Rev. Joseph B. Jorgbor and Rev. Mot. Theresa L. Jorgbor.  
                                              Mr. Jorgbor is engage and have a kid. He is also a senior student 
                                              of the University of Liberia and a student 
                                              of the Living Faith Bible College trying to earn a master degree and a PHD in Theology. 
                                              "I enjoy doing music and I am glad that the Spirit of God is using me to minister his words through 
                                              music."
                                          </p>
                        </div>
                    </div>

          <footer>
             <div className="glojes-nav-but">
                 <h5 className="glojes-button-name">This Glorious Jesus </h5>
                 <FaYoutube  className="icons-youtube"/>
                 <FaFacebook className="icons-facebook"/>
                 <FaWhatsapp className="icons-whatsapp"/>
             </div>
          </footer>
    </div>
  )
}

export default Musicpastorpoppop