import React from 'react'

const NotFound = () => {
  return (
   
    <div>
       <br/>
       <br/>
      <h1>Ops! Page not found</h1>
    </div>
  )
}

export default NotFound
