import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';



const Addmembers = () => {

    const {id} = useParams();

    const [inputData, setInputData] = useState ({
        name:'',
        tone:'',
        contact: '',
        pcontact: ''

    })

    const navigate = useNavigate()

    useEffect (() => {
       axios.get('https://api.publicapis.org/categories')
       .then(res => console.log(res.data))
       .catch(err => console.log)                             
    }, [])
                                            
    const handleSubmit = (event) => {
        event.preventDefault(); 
        axios.post('https://api.publicapis.org/categories', inputData)
        .then(res =>{
            alert("New member added sucessfully!")
            navigate ('/')
        })
        
    }

  return (
    <div>
      
                    <form  onSubmit={handleSubmit} className="add-form"> 
                           <h1>ADD MEMBERS</h1>
                           <input className="add-name" type="text" placeholder="Name" name="name"
                           onChange={e => setInputData({... inputData, name: e.target.value})}
                           />
                           <br />
                           <input className="add-name" type="text" placeholder="Tone" name="tone"
                           onChange={e => setInputData({... inputData, tone: e.target.value})}
                           />
                           <br />
                           <br />
                           <input className="add-name" type="text" placeholder="Contact" name="contact"
                           onChange={e => setInputData({... inputData, contact: e.target.value})}
                           />
                           <br />
                           <br />
                           <input className="add-name" type="text" placeholder="Parent's Contact" name="pcontact"
                           onChange={e => setInputData({... inputData, pcontact: e.target.value})}
                           />
                           <br /><br /><br />
                           <button className="summit-btn">SUMMIT</button>

                           <Link to="/Account"><button className="back-btn">BACK</button></Link>
                           
                           
                     
                    </form>

    </div>
  )
}

export default Addmembers
