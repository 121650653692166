export enum ContentEnum {
  ABOUT = 1,
  ADVERTISEMENT = 2,
  ANNOUNCEMENT = 3,
  HOME_CONTENT = 4,
  CAUTION = 5,
  LIBERIAN_SONGS = 6,
  SECURITY = 7,
  GOAL = 8,
  ETHICS = 9,
  GUIDE_LINE = 10,
  RULES = 11,
  VOICES = 12,
  PERFORMANCE = 13,
  SUMMIT = 14,
  SUMMIT_MARQUEE = 15,
  BEFORE_YOU_START = 16,
  SONG_TITLE = 17,
}

export enum ActionEnum {
  UserId = "USER_ID",
  ProfileId = "PROFILE_ID",
  Authenticated = "AUTHENTICATED",
  AccessToken = "ACCESS_TOKEN",
}
