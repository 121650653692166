import React, { useState, useEffect } from "react";
import { useRef } from "react";
import img46 from "./images/logocrossglo.png";
import img47 from "./images/glojessafe.jpg";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import "../components/MyStyling/majoy.css";
import { useSelector } from "react-redux";

function Navbar() {
  const authenticated = useSelector((state) => state.userData.authenticated);
  const [state, setstate] = useState(false);
  const showDropDown = () => {
    setstate(true);
  };

  const hideDropDown = () => {
    setstate(false);
  };

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <div>
        <img className="logo" src={img46} alt="" />
      </div>

      <div>
        <img className="glosafe" src={img47} alt="" />
      </div>

      <nav className="nav-nav" ref={navRef}>
        <a href="/#">
          <Link to="/">Home</Link>
        </a>
        <a href="/#">
          <Link to="/Rules">Rules</Link>
        </a>
        <a href="/#">
          <Link to="/Concerts">Concerts</Link>
        </a>
        <a href="/#">
          <Link to="/Songs">Songs</Link>
        </a>
        <a
          className="dropdown-menu"
          onMouseEnter={showDropDown}
          onMouseLeave={hideDropDown}
        >
          About
          {state ? (
            <ul className="dropdown-list-list" onMouseEnter={showDropDown}>
              <br />
              <br />
              <li>
                <Link className="dropdown-pop" to="/Awards">
                  Awards
                </Link>
              </li>{" "}
              <br />
              <hr />
              <br />
              <li>
               
              </li>{" "}
              <br />
              <hr />
              <br />
              <li>
                <Link className="dropdown-pop" to="/About">
                  History
                </Link>
              </li>
              <br />
            </ul>
          ) : null}
        </a>

        {authenticated ? (
          <a href="/#">
            <Link to="/Profile">Profile</Link>
          </a>
        ) : (
          <a href="/#">
            <Link to="/Login">Login</Link>
          </a>
        )}
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
